/* @import url("https://fonts.googleapis.com/css2?family=Moirai+One&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap"); */

@font-face {
  font-family: "MabryPro";
  font-weight: 200;
  src: url("/fonts/MabryPro-Black") format("truetype");
}

html {
  scroll-snap-type: y mandatory;
  background-color: #ebe6d9;
}
body {
  margin: 0px;
  font-family: -apple-system, MabryPro, sans-serif !important;
}

.AppBody {
  overflow-x: hidden;
  display: flex;
  flex: 1;
  background-color: white;
}
.AppBody * {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  color: black;
}

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}

.landing {
  width: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.landing-inner {
  max-width: 1300px;
  width: 100%;
}

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

canvas {
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  image-rendering: optimize-contrast;
}

.box {
  width: 100vw;
  border-radius: 5px;
  box-shadow: 0 2px 30px rgba(black, 0.2);
  background: lighten(#f0f4c3, 10%);
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.wave {
  opacity: 0.4;
  position: absolute;
  top: 3%;
  left: 50%;
  background: #2dc6ab;
  width: 300vh;
  height: 300vh;
  margin-left: -150vh;
  margin-top: -220vh;
  transform-origin: 50% 48%;
  border-radius: 43%;
  animation: drift 6000ms infinite linear;
}

.wave.-three {
  animation: drift 8000ms infinite linear;
}

.wave.-two {
  animation: drift 10000ms infinite linear;
  opacity: 0.3;
  background: #a1d1cc;
}

.box:after {
  content: "";
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(#e8a, 1),
    rgba(#def, 0) 80%,
    rgba(white, 0.5)
  );
  z-index: 11;
  transform: translate3d(0, 0, 0);
}

.title {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  line-height: 300px;
  text-align: center;
  transform: translate3d(0, 0, 0);
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.4em;
  font-size: 24px;
  text-shadow: 0 1px 0 rgba(black, 0.1);
  text-indent: 0.3em;
}
@keyframes drift {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}

.animated-title {
  font-size: 60px;
  font-weight: 300;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  padding: 100px 0;
  overflow-x: hidden;
  overflow-y: hidden;
}
.animated-title .track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 60s linear infinite;
}
@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
@media (hover: hover) and (min-width: 700px) {
  .animated-title .content {
    -webkit-transform: translateY(calc(100% - 8rem));
    transform: translateY(calc(100% - 8rem));
  }
}

.contentV {
  height: 100vh;
  width: 100%;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nanum-pen {
  font-family: "Nanum Brush Script", cursive;
  font-weight: 600;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css?family=Raleway:900&display=swap");

body {
  margin: 0px;
}

#container01 {
  filter: url(#threshold) blur(0.6px);
}

#text1,
#text2 {
  position: absolute;
  width: 100%;
  display: inline-block;

  font-family: "Raleway", sans-serif;
  font-size: 80pt;

  text-align: center;

  user-select: none;
}

.css-z1o969-MuiInputBase-root-MuiOutlinedInput-root {
  height: 5.5vh;
  min-height: 45px !important;
}
.css-tzrvf0 {
  height: 5.5vh;
  min-height: 45px !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-radius: 50px !important;
  background: rgba(0, 0, 0, 0.05) !important;
}
.css-igs3ac {
  border: none !important;
  border-radius: 50px !important;
  background: rgba(0, 0, 0, 0.05) !important;
}
.slider .animate {
  flex-direction: row !important;
}
.carousel.carousel-slider {
  height: 100% !important;
}
.carousel .slider-wrapper {
  height: 100% !important;
}
.carousel .slider {
  flex-direction: row !important;
  height: 100% !important;
}
.carousel .slide {
  height: 100% !important;
}
body > main > div:nth-child(1) > style {
  display: none !important;
}
.notAutoWidth {
  width: 100%;
}
.carousel .slider img {
  width: auto !important;
}
body
  > main
  > div
  > div:nth-child(2)
  > div
  > div
  > div
  > label
  > div
  > div:nth-child(2)
  > div
  > ul {
  flex-direction: row !important;
}

.carousel .thumbs {
  flex-direction: row !important;
}
